// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import FBService from '@cybus/helps/dist/helpers/FBService';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import getDomainKey from './helpers/getDomainKey';
import Permissions from './helpers/Permissions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD_Qy29h_SkPKolYlzmdpA_sgK-HcEIykc',
  authDomain: 'vollmar-const.firebaseapp.com',
  projectId: 'vollmar-const',
  storageBucket: 'vollmar-const.appspot.com',
  messagingSenderId: '1070027069163',
  appId: '1:1070027069163:web:cf3c0f36ea332bfa0ad0ae',
  measurementId: 'G-1CQ4EWSLH2',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
const DB = getFirestore(app);

Permissions();

FBService.register({
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  DB,
});
FBService.registerAccountId(getDomainKey());

export default DB;
