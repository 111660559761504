import React, {
  JSXElementConstructor,
  MutableRefObject,
  ReactElement,
  ReactNode,
  cloneElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import './Bar.css';
import BarReducer, { IBarData, IBarItem, IBarItemSmall } from './reducer';
import { Hash } from '@cybus/helps/dist/types/Hash';
import clickOutside from '@cybus/helps/dist/helpers/clickOutside';

type BarChild = ReactElement<any, string | JSXElementConstructor<any>>;

const selectors = {
  TARGETER: '[data-target]',
  TARGETED: '[data-target-name]',
};

function getSelector(type: keyof Hash<IBarItem>, targeted: boolean = false) {
  return `[data-target${targeted ? '-name' : ''}="${type}"]`;
}

const getElAll = (cont: HTMLElement, sel: string): NodeListOf<Element> => cont.querySelectorAll(sel);
const getEl = (cont: HTMLElement, sel: string): Element | null => cont.querySelector(sel);

function observe(container: HTMLElement, onChange: (v: IBarData) => void) {
  const targeters = getElAll(container, selectors.TARGETER);
  const targeted = getElAll(container, selectors.TARGETED);
  const containerStyle = getComputedStyle(container);
  const data: IBarData = {
    gap: parseFloat(containerStyle.gap) || 0,
    padding: { left: parseFloat(containerStyle.paddingLeft) || 0, right: parseFloat(containerStyle.paddingRight) || 0 },
    groups: [],
    keyed: {},
    small: {},
  };
  // do these first so we can populate widths on the next loop.
  targeted.forEach((el: Element) => {
    const e = el as HTMLElement;
    const type = e.dataset.targetName as keyof Hash<IBarItemSmall>;
    data.small[type] = data.small[type] || {
      show: true,
      type,
      selector: getSelector(type, true),
      width: e.offsetWidth,
    };
  });
  targeters.forEach((el: Element) => {
    const e = el as HTMLElement;
    const type = e.dataset.target as keyof Hash<IBarItem>;
    if (!data.keyed[type]) {
      data.keyed[type] = { index: 0, open: 0, width: 0, items: [] };
      data.groups.push(data.keyed[type]);
    }
    const group = data.keyed[type];
    group.items.push({
      show: true,
      type: type as string,
      selector: getSelector(type),
      index: group.items.length,
      width: e.offsetWidth,
    });
    group.open = group.items.length;
  });
  const observer = new ResizeObserver((evt) => {
    const result = BarReducer(evt[0].target as HTMLElement, data);
    if (result !== data) {
      onChange(result);
    }
  });
  observer.observe(container);
  return () => {
    observer.unobserve(container);
  };
}

interface BarButtonTarget {
  'data-target'?: string;
}
interface BarButtonNamedTarget {
  'data-target-name': string;
}

type BarButtonRequiredAttributes = BarButtonTarget | BarButtonNamedTarget

export interface BarProps {
  className?: string;
  children?: (ReactElement & { props: BarButtonRequiredAttributes })[];
  menuTemplate: BarMenuTemplate;
  menuAnchor?: ModalAlignment;
}
export interface IBarMenuProps {
  text: string;
  onClick?: (...args: any[]) => void | (() => void);
}
export type BarMenuTemplate = (props: { items: IBarMenuProps[] }) => ReactElement;
function hidden(data: IBarData, type: keyof Hash<BarChild>, index: number): string {
  return data?.keyed?.[type]?.items?.[index]?.show === false ? 'hide' : '';
}

const requiredProps = {
  TARGETED: 'data-target-name',
  TARGETER: 'data-target',
};
function hasRequiredProps(child: BarChild): boolean {
  for (const i in requiredProps) {
    const s = requiredProps[i as keyof Object];
    if (child.props[s as any]) {
      return true;
    }
  }
  return false;
}

function prepClones(
  data: IBarData,
  children: BarChild[],
  menuProps: Hash<IBarMenuProps[]>,
  setSelectedMenu: (menu: string) => void
): ReactNode {
  const indexes: Hash<number> = {};
  return children?.map((child, index) => {
    const c = child as BarChild;
    if (c) {
      if (hasRequiredProps(c)) {
        const props: Hash<any> = { key: `barc-${index}`, className: `${c.props.className} ` };
        const targeter: string = child.props[requiredProps.TARGETER];
        const targeted: string = child.props[requiredProps.TARGETED];
        if (targeter) {
          indexes[targeter] = indexes[targeter] || 0;
          props.className += hidden(data, targeter, indexes[targeter]);
          indexes[targeter] += 1;
          menuProps[targeter] = menuProps[targeter] || [];
          menuProps[targeter].push({
            text: child.props.children,
            onClick: child.props.onClick || (() => {}),
          });
        } else if (targeted) {
          props.className += data.small?.[targeted].show === false ? 'hide' : '';
          props.onClick = () => setSelectedMenu(targeted); // we don't care about this one.
        }
        return cloneElement(c, props);
      } else {
        return child; // we don't care about this one.
      }
    }
  });
}

function createMenu(
  data: IBarData,
  selectedMenu: string,
  setSelectedMenu: (m: string) => void,
  menuProps: Hash<IBarMenuProps[]>,
  menuTemplate: BarMenuTemplate
) {
  if (!selectedMenu || !data?.keyed?.[selectedMenu]) {
    return <></>;
  }
  const menu: IBarMenuProps[] = [];
  const selected = data.keyed[selectedMenu];
  for (let i = 0; i < selected.items.length; i += 1) {
    const item = selected.items[i];
    if (!item.show) {
      const menuItem = menuProps[selectedMenu][i];
      menu.push({
        text: menuItem.text,
        onClick: (...args: any[]) => {
          setSelectedMenu('');
          menuItem.onClick?.(...args);
        },
      });
    }
  }
  return menuTemplate({ items: menu });
}

type ModalAlignment = 'left' | 'center' | 'right';

function getMenuOffset(
  ref: React.MutableRefObject<HTMLElement | null>,
  data: IBarData,
  selectedMenu: string,
  menuAnchor: ModalAlignment,
  menuWidth: number,
  container: HTMLElement
): { left?: string; transform?: string } {
  if (data?.small?.[selectedMenu]) {
    const menuBtnEl = getEl(ref.current as HTMLElement, data.small[selectedMenu].selector) as HTMLElement;
    if (menuBtnEl) {
      const val = { left: 0, transform: 0 };
      const left = menuBtnEl.offsetLeft;
      const width = menuBtnEl.offsetWidth;
      switch (menuAnchor) {
        case 'right':
          val.left += left + width;
          val.transform = -1;
          break;
        case 'center':
          val.left = left + width * 0.5;
          val.transform = -0.5;
          break;
        default:
          val.left = left;
      }
      const v = val.left + menuWidth * val.transform;
      if (v < 0) {
        val.left += Math.abs(v);
      }
      const rightOfAnchor = menuAnchor === 'left' ? menuWidth : menuAnchor === 'center' ? (menuWidth - width) * 0.5 : 0;
      const overallWidth = v + rightOfAnchor;
      if (overallWidth > container.offsetWidth) {
        val.left += container.offsetWidth - overallWidth;
      }
      return { left: val.left + 'px', transform: `translateX(${val.transform * 100}%)` };
    }
  }
  return { left: '0px' };
}

export default function Bar({ className = '', children, menuTemplate, menuAnchor = 'left' }: BarProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [data, setData] = useState({} as IBarData);
  const [selectedMenu, setSelectedMenu] = useState('none');
  const [modalWidth, setModalWidth] = useState(0);
  const menuProps: Hash<IBarMenuProps[]> = {};
  const clones = prepClones(data, children as BarChild[], menuProps, setSelectedMenu);
  const menu = createMenu(data, selectedMenu, setSelectedMenu, menuProps, menuTemplate);
  const selectedMenuOffset = selectedMenu
    ? getMenuOffset(ref, data, selectedMenu, menuAnchor, modalWidth, ref.current as HTMLElement)
    : 0;

  // must be separate. returns it own destory.
  useEffect(() => {
    return observe(ref.current as HTMLElement, setData);
  }, []);
  // must be separate. returns it own destory.
  useEffect(() => {
    return clickOutside(menuRef.current as HTMLDivElement, () => {
      setSelectedMenu('');
    });
  }, []);
  // positioning modal needs to know modal size.
  useEffect(() => {
    if (menu && !modalWidth && menuRef.current) {
      setModalWidth((menuRef.current as HTMLElement).offsetWidth);
    } else if (!menu && modalWidth) {
      setModalWidth(0);
    }
  });
  return (
    <>
      <div ref={ref} className={`bar-container ${className}`}>
        {clones}
        {menu && (
          <div
            ref={menuRef}
            style={{
              ...selectedMenuOffset,
              position: 'absolute',
              bottom: '100%',
            }}
          >
            {menu}
          </div>
        )}
      </div>
    </>
  );
}
