import React from 'react';
import Col from '../../components/Col';
import Row from '../../components/Row';
import { useDispatch, useSelector } from 'react-redux';
import { getDrawerOpen, setDrawerOpen } from '.';
import { CRUD, IPermission } from '../permissions/IPerm';
import { getPermission } from '../permissions';
import { IRoute } from './IRoute';

interface DrawerProps {
  className?: string;
  items: IRoute[];
  router: { navigate: (p: string) => void };
}

export default function Drawer({ items, className, router }: DrawerProps) {
  const open = useSelector(getDrawerOpen);
  const permission = useSelector(getPermission);
  const dispatch = useDispatch();

  const navigate = (path: string): void => {
    close();
    router.navigate(path);
  };

  const close = () => {
    dispatch(setDrawerOpen(false));
  };

  const buttons = () => {
    return items.map(
      (item: { label?: string; title?: string; path: string; drawer?: { key: keyof IPermission; value: CRUD } }, i) => {
        if (item.drawer && (permission?.[item.drawer.key] as CRUD) >= item.drawer.value) {
          return (
            <li key={`side-btn-${i}`} className="border border-b-neutral">
              <button onClick={() => navigate(item.path)} className="btn justify-start items-center text-base">
                {item.label || item.title}
              </button>
            </li>
          );
        }
        return '';
      }
    );
  };
  return (
    <Col
      className={`fixed z-10 top-0 left-0 w-80 min-h-full bg-base-200 text-base-content drop-shadow-xl border-r border-neutral transition-transform ease-in-out transform ${className} ${
        open ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <Row className="w-full h-16 justify-between bg-neutral items-center">
        <a className="btn btn-ghost normal-case">
          <img src="/logo-wtext-white.svg" className="App-logo h-6 object-contain" alt="logo" />
        </a>
        <button className="btn btn-neutral btn-circle btn-sm" onClick={close}>
          X
        </button>
      </Row>
      <ul className="menu pt-0 px-0">{buttons()}</ul>
    </Col>
  );
}
