import { IUser } from './IUser';
import FBService from '@cybus/helps/dist/helpers/FBService';
import hop from '@cybus/helps/dist/helpers/hop';

const service = new FBService<IUser>();

export const fetchUser = (uid: string): Promise<IUser> => {
  return service.fetch(`users`, uid).then((u) => {
    u.addresses = u.addresses || [Object.assign({ street: '', city: '', state: '', zip: '' }, (u as any).address)];
    return u;
  });
};
export const fetchUsers = (): Promise<IUser[]> => {
  return service.fetchAll(`users`);
};

export const saveUser = (user: IUser): Promise<IUser> => {
  for (const i in user) {
    if (hop(user, i) && user[i as keyof typeof user] === undefined) {
      user[i as keyof typeof user] = '' as never;
    }
  }
  return service.save(`users`, user);
};
