import React, { Suspense, useEffect, useLayoutEffect } from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import router, { findRoute, routes } from './routes/router';
import './firebaseConfig';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import MainNav from './components/MainNav';
import Footer from './components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import GLog from '@cybus/helps/dist/helpers/GLog';
import { fetchUser, saveUser } from './reactlib/features/users/services';
import { Spinner } from './reactlib';
import { fetchPermission, savePermission } from './reactlib/features/permissions/services';
import { setPermission } from './reactlib/features/permissions';
import { getLoading, setLoading } from './reactlib/features/loading';
import Drawer from './reactlib/features/drawer/Drawer';
import { IUser } from './reactlib/features/users/IUser';
import extend from '@cybus/helps/dist/helpers/extend';
import { IPermission } from './reactlib/features/permissions/IPerm';
import Toast from './reactlib/features/toast/Toast';
import Dialog from './reactlib/features/dialog/Dialog';
import { setUser } from './reactlib/features/users';
import LogRocket from 'logrocket';
import displayName from '@cybus/helps/dist/helpers/displayName';
import { addressToStr } from '@cybus/helps/dist/types/Address';
import OneSignlaSetup from './helpers/OneSignalSetup';
import { getAccount, setAccount } from './reactlib/features/account';
import { fetchAccount } from './reactlib/features/account/services';

const gl = GLog.init('App', 'blue', 1);

function App() {
  const { large } = useSelector(getLoading);
  const account = useSelector(getAccount);
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    fetchAccount().then((account) => {
      dispatch(setAccount(account));
    });
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user?.uid) {
        unsubscribe();
        const found = await fetchUser(user.uid);
        const p = await fetchPermission(user.uid);
        if (!found) {
          // user not saved yet. Save the user.
          const u: IUser = {} as unknown as IUser;
          u.id = user.uid;
          u.uid = user.uid;
          u.displayName = user.displayName || '';
          const parts: string[] = u.displayName.split(/\s+/g);
          u.firstName = parts.shift() || u.email || '';
          u.lastName = parts.pop() || '';
          u.provider = user.providerId || '';
          u.email = user.email || '';
          u.phone = user.phoneNumber || '';
          u.emailVerified = user.emailVerified || false;
          saveUser(u).then(async () => {
            // after user is created
            const perms: IPermission = {
              id: u.id,
              appts: 0,
              contracts: 0,
              customers: 0,
              dev: 0,
              login: 1,
              permissions: 0,
              users: 0,
            } as unknown as IPermission;
            savePermission(perms)
              .catch((e) => {})
              .finally(() => {
                document.location.reload();
              });
            return;
          });
          extend(found, u);
          dispatch(setUser(found));
        } else {
          dispatch(setUser(found));
          dispatch(setPermission(p));
        }
        LogRocket.identify(found.id, {
          name: displayName(found, false),
          phone: found.phone,
          email: found.email,
          address: addressToStr(found.addresses[0]),
        });
        OneSignlaSetup(found, p);
      }
      dispatch(setLoading([true, true]));
      // if they are not logged in. force them to the login page
      const path = document.location.pathname;
      const route = findRoute(path);
      if (route?.role && !user?.uid && path !== '/') {
        router.navigate('/');
        return;
      }
      if (user?.uid && path === '/') {
        router.navigate('/dashboard');
      }
    });
  }, []);

  useEffect(() => {
    gl.log('render');
  });

  return (
    <div className="App min-h-full h-1 flex flex-col justify-between">
      <header className="App-header w-full fixed h-16 z-10 no-print">
        <MainNav />
      </header>
      <Drawer items={routes} router={router} />
      <div className="pt-16" style={{ minHeight: '100%' }}>
        <Suspense fallback={<div className="absolute top-0 left-0 right-0 bottom-0 bg-white"></div>}>
          <RouterProvider router={router} />
        </Suspense>
      </div>
      <Footer />
      <Dialog />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none">
        <Spinner loaded={large} bg={large ? 'bg-white' : 'bg-transparent'} color="blue" />
      </div>
      <Toast className="fixed bottom-0 right-0 z-50 w-48" itemClassName="w-48 h-14 top-8 right-1 shadow" />
    </div>
  );
}

export default App;
