import React, { ReactNode } from 'react';
import Col from '../Col';

export interface ICard {
  title: string;
  desc: string;
  img?: string;
  children?: ICard[];
}
interface CardProps {
  className?: string;
  title?: string | ReactNode
  desc?: string | ReactNode
  children?: ReactNode;
}

const Card = ({ title, desc, className, children }: CardProps) => {
  return (
    <Col className={`border bg-white drop-shadow-lg rounded-md ${className || ''} overflow-auto`}>
      <div className="text-xl">{title}</div>
      <div className="text-sm">
        <Col>{desc}</Col>
        {children}
      </div>
    </Col>
  );
};

export default Card;
