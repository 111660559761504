import flattenDate from '@cybus/helps/dist/helpers/flattenDate';
import { Address } from '@cybus/helps/dist/types/Address';
import { ICustomer, getDefaultCustomer } from '../../reactlib/features/customer';
import { ISignature } from '../../reactlib/components/SignatureDraw';
import pathResolve from '@cybus/helps/dist/helpers/pathResolve';
import extend from '@cybus/helps/dist/helpers/extend';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { sortByAll } from '@cybus/helps/dist/helpers/sortBy';
import createReduxListFeature from '@cybus/helps/dist/helpers/createReduxListFeature';

export const ContractTypes = ['window', 'roofing', 'solar'] as const;
export type IContractType = (typeof ContractTypes)[number];
export interface Initial {
  value: string;
  ts: number;
}
export interface IContract {
  id: string;
  sellerId: string;
  installerId: string;
  type: IContractType;
  date: number;
  installDate: number;
  customer: ICustomer;
  install: Address;
  billing: Address;
  products: any[];
  notes: string;
  price: {
    total: number;
    down: number;
  };
  approved: { uid: string; date: number };
  initials: Initial[];
  signatures: ISignature[];
  signed?: boolean; // once signed it should be locked.
}

export const applyToDocumentContact = (
  model: IContract,
  path: string,
  value: string | number | Address | ICustomer | ISignature | { value: string; ts: number }
): IContract => {
  const copy = extend({}, model);
  pathResolve(copy, path, value);
  console.log(copy);
  return copy;
};

export const getDefaultContract = (): IContract => {
  return {
    id: '',
    type: 'window',
    sellerId: '',
    installerId: '',
    date: flattenDate(Date.now(), 'day').getTime(),
    installDate: 0,
    customer: getDefaultCustomer(),
    install: { street: '', city: '', state: '', zip: '' },
    billing: { street: '', city: '', state: '', zip: '' },
    products: [],
    notes: '',
    price: { total: 0, down: 0 },
    approved: { uid: '', date: 0 },
    initials: [
      { ts: 0, value: '' },
      { ts: 0, value: '' },
    ],
    signatures: [{ ts: 0, path: '', name: '', email: '', note: '' }],
  };
};

const sort = sortByAll(['signature.date', 'date']);

export const contractSlice = createSlice(createReduxListFeature('contracts', getDefaultContract, sort));

export const {
  setValue: setContract,
  setList: setContracts,
  update: updateContract,
  remove: removeContract,
} = contractSlice.actions;

export const getContract = (state: RootState) => state.contracts.value;
export const getContracts = (state: RootState) => state.contracts.list;
export const getContractsById = (state: RootState) => state.contracts.hash;

export const contractReducer = contractSlice.reducer;
