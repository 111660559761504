import flattenDate from '@cybus/helps/dist/helpers/flattenDate';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { DAY } from '@cybus/helps/dist/helpers/formatDate';
import { Address } from '@cybus/helps/dist/types/Address';
import createReduxListFeature from '@cybus/helps/dist/helpers/createReduxListFeature';
import sortBy from '@cybus/helps/dist/helpers/sortBy';
import { IContractType } from '../contracts';

export interface IAppointment {
  id: string;
  customerId: string;
  appointerId: string;
  sellerId: string;
  contractType: IContractType;
  images: string[];
  date: number;
  address: Address;
}

export const getDefaultAppointment = (): IAppointment => {
  const date = flattenDate(Date.now() + DAY, 'day');
  date.setHours(8);
  return {
    id: '',
    customerId: '',
    appointerId: '',
    sellerId: '',
    contractType: 'window',
    images: [],
    date: date.getTime(),
    address: { street: '', city: '', state: '', zip: '' },
  };
};

const sort = sortBy('date');
export const appointmentSlice = createSlice(createReduxListFeature('appointment', getDefaultAppointment, sort));

export const {
  setValue: setAppointment,
  setList: setAppointments,
  update: updateAppointment,
  remove: removeAppointment,
} = appointmentSlice.actions;

export const getAppointment = (state: RootState) => state.appointment.value;
export const getAppointments = (state: RootState) => state.appointment.list;
export const getAppointmentById = (state: RootState) => state.appointment.hash;

export const appointmentReducer = appointmentSlice.reducer;
