import { Hash } from '@cybus/helps/dist/types/Hash';
import getDiff from '@cybus/helps/dist/helpers/getDiff';
import extend from '@cybus/helps/dist/helpers/extend';

export interface IBarItemSmall {
    type: string;
    show: boolean;
    selector: string;
    width: number;
}

export interface IBarItem {
    show: boolean;
    type: string;
    selector: string;
    index: number;
    width: number;
}

export interface IBarGroup {
    index: number;
    width: number;
    open: number;
    items: IBarItem[];
}

export interface IBarData {
    gap: number;
    padding: { left: number; right: number };
    groups: IBarGroup[];
    keyed: Hash<IBarGroup>;
    small: Hash<IBarItemSmall>;
}

interface IBarProcessProps {
    available: number;
    accumulative: number;
    dropped: IBarItem[];
    added: { key: string; value: number }[];
}

function getValuesFromStyle(child: HTMLElement, data: IBarData) {
    let count = 0;
    for (let i = 0; i < child.children.length; i += 1) {
        const c = child.children[i];
        const display = getComputedStyle(c).display;
        if (display !== "none") {
            count += 1;
        }
    }
    return data.gap * count + data.padding.left + data.padding.right;
}

function getAvailableWidth(container: HTMLElement, data: IBarData) {
    return container.offsetWidth - getValuesFromStyle(container, data);
}

function getWidths(data: IBarData, item: IBarItem) {
    const small = !item.index ? data.small[item.type].width + data.gap : 0;
    const width = item.width + data.gap;
    return { small, width, value: width - small };
}

function showItem(data: IBarData, group: IBarGroup, item: IBarItem, props: IBarProcessProps) {
    const width = item.width + data.gap;
    group.width += width;
    group.open += 1;
    props.accumulative += width;
    if (!item.index) {
        data.small[item.type].show = false;
    }
}

function hideItem(data: IBarData, group: IBarGroup, item: IBarItem, props: IBarProcessProps) {
    const widths = getWidths(data, item);
    item.show = false;
    props.accumulative -= widths.value;
    group.open -= 1;
    data.small[item.type].show = true;
}

function reduce(data: IBarData, group: IBarGroup, item: IBarItem, props: IBarProcessProps): void {
    if (props.accumulative > props.available) {
        let selectedItem = item;
        let selectedGroup = group;
        while (selectedItem && selectedGroup && props.accumulative > props.available) {
            if (selectedItem.show) {
                hideItem(data, selectedGroup, selectedItem, props);
            }
            if (selectedGroup.items[selectedItem.index + 1]) {
                selectedItem = selectedGroup.items[selectedItem.index + 1];
            } else {
                selectedGroup = data.groups[selectedGroup.index + 1];
                selectedItem = selectedGroup?.items[0] || null;
            }
        }
    }
}

function hasChanged(data: IBarData, copy: IBarData) {
    return getDiff(copy.keyed, data.keyed);
}

function runScenario(data: IBarData, props: IBarProcessProps) {
    // add closed menus
    for (let g = data.groups.length - 1; g >= 0; g -= 1) {
        const group = data.groups[g];
        group.index = g;
        group.open = 0;
        group.width = 0;
        if (!group.open) {
            for (const item of group.items) {
                showItem(data, group, item, props);
            }
        }
    }
    reduce(data, data.groups[0], data.groups[0].items[0], props);
}

function copyData(d: IBarData, expandAll: boolean = false): IBarData {
    const copy = extend({}, d); // deep copy
    // make sure that keyed and groups are the same instances.
    for (const group of copy.groups) {
        const type = group.items[0].type as keyof Hash<IBarData>;
        if (expandAll) {
            for (const item of group.items) {
                item.show = true;
            }
        }
        copy.keyed[type] = group;
    }
    return copy;
}

export default function BarReducer(container: HTMLElement, data: IBarData) {
    const copy = copyData(data, true);
    const available = getAvailableWidth(container, copy);
    const props: IBarProcessProps = { accumulative: 0, available, dropped: [], added: [] };
    runScenario(copy, props);
    if (props.accumulative && hasChanged(data, copy)) {
        extend(data, copy);
        return copy;
    }
    return data;
}
