// @ts-nocheck
import hop from '@cybus/helps/dist/helpers/hop';
import { CRUD, IPermission } from '../reactlib/features/permissions/IPerm';
import { IUser } from '../reactlib/features/users/IUser';

let initialized = false;
export default function OneSignlaSetup(user: IUser, permission: IPermission) {
  if (!initialized && document.location.hostname === 'vollmarpro.net') {
    initialized = true;
    const appId = '8950cedd-dd42-4b74-88be-3b3d914c0738';
    // limit 2 tags on free plan.
    // TODO: this needs to be triggered by a notification preferences in the admin.
    const tags = {
      leads: permission.customers > CRUD.CREATE ? 1 : 0,
      sales: permission.customers > CRUD.CREATE ? 1 : 0,
    };
    const script = document.createElement('script');
    script.src = 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js';
    script.defer = true;
    script.onload = () => {
      window.OneSignalDeferred = window.OneSignalDeferred || [];
      OneSignalDeferred.push(function (OS) {
        OS.init({ appId })
          .then(() => OS.login(user.id)) // creates a user if they don't exist with external_id
          .then(() => {
            const u = OS.User._currentUser;
            u?.addTags(tags);
          }).catch(e => {
            if (globalThis.window && globalThis.document.location.href.indexOf('localhost')) {
              console.log(e);
            }
          });
      });
    };
    document.head.appendChild(script);
  }
}
